@media (max-height: 900px) {
  .auth-sign-up-scrollbar {
    height: 51vh;
  }

  .review-info-section .auth-sign-up-scrollbar {
    height: 100%;
  }

  .cs-left-section .auth-image img {
    max-width: 80%;
  }
}

@media (max-height: 700px) {
  .auth-sign-up-scrollbar {
    height: 41vh;
  }
}

/*========== START Laptop - Viewport ========== */
@media (max-width: 1699px) {
  .billing-box .cs-flex {
    padding-right: 0;
  }
}

@media (max-width: 1599px) {
  .billing-box span.cs-neutral-100 {
    margin-inline: 10px;
  }

  .billing-box span.cs-neutral-100 svg {
    width: 28px;
  }
}

@media (max-width: 1499px) {
  .cs-table-widget {
    width: 100%;
    max-width: 1499px;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 4px;
  }

  .cs-left-section .auth-image {
    width: 70%;
    height: calc(100% - 11vw);
    margin: 0 auto;
  }

  .cs-table-widget table {
    width: 100%;
  }

  .auth-section .row {
    --bs-gutter-x: 1.5rem;
  }

  .auth-section .container-fluid {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .cs-avatar-xs {
    width: 40px;
    height: 40px;
  }

  .cs-section-top-bar .cs-search-bar {
    max-width: 290px;
  }

  .cs-action-btn span {
    margin-right: 10px;
  }

  .financial-advisor-top-bar .cs-action-btn {
    left: calc(100% - 330px);
  }

  .select-dropdown .css-1nmdiq5-menu,
  .cs-dropdown .css-1nmdiq5-menu {
    max-height: 100px;
  }

  .settings-main-section .template4-section {
    padding-inline: 30px;
  }

  /* .settings-main-section .template-bg,
  .settings-main-section .preview-brand .choose-temp-design {
    height: 65vh;
  } */

  .settings-main-section .fullscreen-preview .template-bg,
  .settings-main-section .fullscreen-preview .preview-brand .choose-temp-design {
    height: 100%;
  }

  .cs-main-layout .settings-main-section .branding-container {
    height: 100%;
  }

  .settings-main-section .branding-right {
    height: calc(100vh - 34vh);
  }

  .settings-main-section .choose-temp-design {
    height: 52vh;
  }

  .settings-main-section .template4-section .row:first-child .col:not(.col:first-child) {
    height: calc(100% - 80px) !important;
    margin-left: auto;
  }

  .settings-main-section .template2-section .template-right-section {
    min-height: calc(38vw - 14vw) !important;
  }

  .settings-main-section .template4-section .template-right-section {
    padding: 15px;
  }

  .settings-main-section .template-bg {
    height: 54vh;
  }

  .cs-preview-box.cs-logo-box {
    padding-block: 11px;
  }

  .cs-preview-box.cs-logo-box .cs-common-spinner.cs-primary {
    padding-block: 0;
  }

  .settings-main-section .color-row .cs-btn-icon-tertiary {
    padding: 0;
  }

  .settings-main-section .branding-section .branding-container .fullscreen-preview.dashboard-full-preview {
    padding-inline: 30px !important;
    height: auto !important;
  }

  .settings-main-section .branding-section .branding-container .fullscreen-preview.dashboard-full-preview .preview-brand {
    height: auto !important;
  }

  .billing-subscription-tab .billing-row .col-lg-4 {
    width: 50%;
  }

  .settings-main-section .settings-body .settings-tab-btn {
    justify-content: space-between;
  }

  .billing-subscription-page .cs-payment-container .col-lg-4 {
    width: 50%;
  }

  .cs-audit-logs-sec .cs-tbody td {
    white-space: nowrap;
  }
}

@media (max-width: 1199px) {
  .cs-section-top-bar .cs-search-bar {
    max-width: 240px;
  }

  .settings-main-section .branding-container>.row:first-child {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
  }

  .settings-main-section .branding-container .row .col-lg-5,
  .settings-main-section .branding-container .row .col-lg-7 {
    width: 100%;
  }

  .settings-main-section .branding-container .row .col-lg-5 {
    margin-bottom: 32px;
  }

  .settings-main-section .upload-image-row .row .col-lg-3,
  .settings-main-section .upload-image-row .row .col-lg-9 {
    width: 100%;
  }

  .cs-main-layout .settings-main-section .branding-container {
    height: 100%;
  }

  .settings-main-section .cs-preview-box {
    height: 100px;
    max-height: 100px;
  }

  .settings-main-section .cs-preview-box img {
    width: 100%;
    height: 100%;
    max-height: 100px;
  }

  .settings-main-section .branding-right {
    height: 100vh;
  }

  .settings-main-section .branding-left .cs-btn-group {
    padding-top: 24px;
  }

  .settings-main-section .template4-section .row:first-child .col:not(.col:first-child) {
    height: 56vh;
    margin-left: auto;
  }

  .settings-main-section .fullscreen-preview .template2-section .template-right-section {
    max-width: 50vw;
    min-height: calc(100vh - 150px);
  }

  .settings-main-section .fullscreen-preview .template4-section {
    padding-inline: 100px;
  }

  .file-encryption-row {
    padding-right: 0;
  }

  .card-specification .card-plan-amount {
    width: 120px;
    justify-content: end;
  }

  .billing-box .cs-flex .cs-semi-bold-h1 {
    font-size: 17px;
    line-height: 27px;
  }

  .billing-box-skeleton .billing-box.add-ons-box .sidebar-add-account span.react-loading-skeleton {
    width: 100% !important;
  }

  .billing-box-skeleton .billing-box.add-ons-box .cs-regular-h5.cs-neutral-60 span {
    width: 90% !important;
  }

  .last-billing span.react-loading-skeleton,
  .next-billing span.react-loading-skeleton {
    display: inline-block;
    width: 90px !important;
  }

  .cs-header-right .cs-header-dropdown .dropdown-menu {
    left: -380px;
  }
}

@media (max-width: 1199px) and (max-height: 767px) {
  .offcanvas-body {
    height: auto;
  }

  .mob-scroller {
    margin-right: -4px;
    padding-right: 4px;
    overflow: hidden;
    overflow-y: auto;
  }
}

@media (min-width: 992px) {
  .cs-header-widget {
    width: calc(100% - 272px);
    position: fixed;
    right: 0;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .isClose .cs-content-area .cs-header-widget {
    width: calc(100% - 72px);
  }

  .navbar-expand {
    position: relative;
  }
}

/* ========== END Laptop - Viewport ========== */

/* ========== START Tablet - Viewport ========== */
@media (max-width: 991px) {
  .for-des {
    display: none !important;
  }

  .for-mob {
    display: block !important;
  }

  .for-flex-des {
    display: none !important;
  }

  .for-flex-mob {
    display: flex !important;
  }

  .cs-radio-group .cs-radio-btn {
    margin-right: 24px;
  }

  .cs-radio-btn-label span,
  .cs-radio-group .cs-radio-btn span {
    margin-left: 0;
  }

  .cs-custom-pagination {
    margin: 0;
  }

  .cs-export-row {
    margin-top: 24px;
  }

  .add-btn {
    display: none;
  }

  .mobile-add-acc-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 36px;
    height: 36px;
    padding: 6px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: var(--primary-main);
    -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
  }

  .cs-toast .Toastify__toast-container {
    width: 300px;
  }

  .modal-dialog {
    padding: 0 32px;
  }

  .settings-main-section .branding-container .row .col-lg-5 {
    margin-bottom: 0;
  }

  .settings-main-section .branding-right {
    height: 100vh;
  }

  .settings-main-section .welcome-text {
    display: none;
  }

  .settings-main-section .color-row .cs-btn-icon-tertiary {
    margin-top: -10px;
  }

  .settings-main-section .fullscreen-preview .template4-section {
    padding-inline: 60px;
  }

  .settings-main-section .fullscreen-preview .template4-section .row:first-child .col:not(.col:first-child) {
    min-width: 400px;
  }

  /* Siderbar skeleton */
  .cs-main-sidebar.skeleton-main-sidebar {
    display: none;
  }

  .cs-main-area .container-fluid {
    padding: 0 !important;
  }

  .cs-header.cs-skeleton-header {
    max-width: 100%;
  }

  .cs-dropdown.select-dropdown {
    margin-bottom: 24px !important;
  }

  .favicon-small .advanced-cropper-boundary.advanced-cropper__boundary,
  .favicon-Avatar .advanced-cropper-boundary.advanced-cropper__boundary {
    height: 394px;
  }

  .cs-form-dropdown .dropdown-toggle span {
    margin-left: 10px;
  }

  .mobile-add-icon {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 100%;
    background: var(--primary-main);
    border-color: var(--primary-main) !important;
    -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    z-index: 2;
  }

  .mobile-add-icon:hover {
    background-color: var(--primary-hover);
  }

  .mobile-add-icon:active,
  .mobile-add-icon:target,
  .mobile-add-icon:focus-visible,
  .mobile-add-icon:focus {
    background-color: var(--primary-pressed) !important;
  }

  .auth-section {
    height: 100vh;
    padding: 0;
    background-color: var(--neutral-10) !important;
  }

  .add-ons-row .col-lg-4:not(:last-child) {
    margin-bottom: 28px;
  }

  .payment-cards .credit-payment-card {
    margin-top: 8px;
  }

  .payment-method {
    margin-bottom: 8px;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
    width: auto;
  }

  .cs-header-notificatins .dropdown-menu,
  .cs-notification {
    width: 430px;
  }
}

@media (max-width: 767px) {

  .settings-main-section .template1-section .align-items-center.row>.col:first-child,
  .settings-main-section .template3-section .align-items-center.row>.col:first-child {
    display: none;
  }

  .settings-main-section .template1-section .align-items-center.row .template-right-section {
    padding: 0;
  }

  .settings-main-section .template3-section {
    padding: 44px 54px;
  }

  .settings-main-section .template4-section .template-right-section {
    padding: 0;
    padding-inline: 3vw;
  }

  .settings-main-section .template4-section .row:first-child .col:not(.col:first-child) {
    margin-left: -35vw;
  }

  .cs-form-group {
    margin-bottom: 16px;
  }

  .cs-user-td,
  .cs-md-modal .cs-table-widget,
  .cs-repeated-scroller,
  .associate-add-more .table-responsive {
    padding-right: 0;
    margin-right: 0;
  }

  .cs-setting-form .cs-btn-primary {
    margin-top: 0 !important;
  }

  .file-encryption-row .change-password-button {
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .cs-dashboard-cards.category-cards {
    margin-bottom: 20px;
  }

  .billing-subscription-tab .billing-row .col-lg-4 {
    width: 100%;
  }

  .billing-box.cs-current-billing {
    margin-bottom: 24px;
  }

  .add-ons-row {
    padding-bottom: 14px;
  }

  .billing-subscription-page .cs-payment-container .col-lg-4 {
    width: 100%;
  }

  .view-and-manage-header {
    margin-bottom: 20px;
  }

  .view-and-manage-container .view-and-manage-top {
    margin: 0;
  }
}

@media (max-width: 700px) {
  .settings-main-section .settings-tab-btn.cs-tabs.for-des {
    display: none;
  }

  .settings-main-section .settings-tab-dropdown.for-mob {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .settings-main-section .settings-tab-dropdown,
  .settings-main-section .settings-tab-dropdown .cs-form-dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }

  .settings-main-section .settings-tab-dropdown .cs-selected-item {
    background-color: var(--primary-surface);
    color: var(--primary-main);
  }
}

/* ========== END Tablet - Viewport ========== */

/* ========== START Mobile - Viewport ========== */
@media (max-width: 575px) {
  .row {
    --bs-gutter-x: 1rem;
  }

  /* ========== START - Typography ========== */

  .cs-semi-bold-h1,
  .cs-regular-h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .cs-semi-bold-h2,
  .cs-regular-h2,
  .cs-medium-sub-heading-xl,
  .cs-regular-sub-heading-xl {
    font-size: 16px;
    line-height: 26px;
  }

  .cs-semi-bold-h3,
  .cs-regular-h3,
  .cs-medium-sub-heading-l,
  .cs-regular-sub-heading-l,
  .cs-regular-body-text-xl,
  .cs-light-body-text-xl {
    font-size: 14px;
    line-height: 22px;
  }

  .cs-semi-bold-h4,
  .cs-regular-h4,
  .cs-medium-sub-heading-m,
  .cs-regular-sub-heading-m,
  .cs-regular-body-text-l,
  .cs-light-body-text-l {
    font-size: 12px;
    line-height: 18px;
  }

  .cs-semi-bold-h5,
  .cs-regular-h5,
  .cs-medium-sub-heading-s,
  .cs-regular-sub-heading-s,
  .cs-regular-body-text-m,
  .cs-light-body-text-m {
    font-size: 10px;
    line-height: 14px;
  }

  .cs-semi-bold-h6,
  .cs-regular-h6,
  .cs-regular-sub-heading-xs,
  .cs-medium-sub-heading-xs,
  .cs-regular-body-text-s,
  .cs-light-body-text-s {
    font-size: 9px;
    line-height: 12px;
  }

  .choose-user-modal span.form-error-msg {
    bottom: -14px;
  }

  /* ========== END - Typography ========== */
  /* ========== START - Common Modal ========== */

  .cs-s-modal,
  .cs-md-modal,
  .cs-lg-modal,
  .cs-xl-modal,
  .modal-dialog.cs-s-modal {
    max-width: 100%;
    margin: 0 auto;
    padding: 24px;
  }

  .cs-md-modal .modal-header,
  .cs-lg-modal .modal-header {
    padding: 16px;
    text-align: center;
  }

  .cs-md-modal .modal-body,
  .cs-lg-modal .modal-body,
  .cs-add-acc-modal,
  .modal-body {
    padding: 24px 16px;
  }

  .cs-md-modal .modal-footer,
  .cs-lg-modal .modal-footer,
  .modal-footer {
    padding: 0px 16px 24px 16px;
    border: none;
    -ms-flex-wrap: initial;
    flex-wrap: initial;
  }

  .cs-divider {
    width: 100%;
  }

  .cs-s-modal .modal-header {
    padding: 16px;
    text-align: center;
  }

  /* ========== END - Common Modal ========== */
  /* ========== Start - Buttons ========== */
  .cs-btn-primary,
  .cs-btn-secondary,
  .cs-btn-tertiary,
  .cs-btn-danger,
  .cs-btn-icon-primary,
  .cs-btn-icon-secondary,
  .cs-btn-icon-tertiary,
  .cs-btn-primary:disabled,
  .cs-btn-icon-primary:disabled,
  .cs-btn-danger:disabled {
    padding: 6px 8px !important;
    border-width: 1px !important;
  }

  .cs-btn-primary:disabled:focus,
  .cs-btn-icon-primary:disabled:focus,
  .cs-btn-danger:disabled:focus,
  .cs-btn-primary:disabled:focus-visible,
  .cs-btn-icon-primary:disabled:focus-visible,
  .cs-btn-danger:disabled:focus-visible,
  .cs-btn-primary:disabled:active,
  .cs-btn-icon-primary:disabled:active,
  .cs-btn-danger:disabled:active {
    padding: 6px 8px !important;
    border-width: 1px !important;
  }

  /* ========== End - Buttons ========== */

  .last-form-field,
  .last-form-field .cs-form-group,
  .resp-last-field,
  .resp-last-field .cs-form-group {
    margin-bottom: 0;
  }

  .cs-form-group,
  .cs-form-dropdown,
  .cs-dropdown.select-dropdown,
  .choose-user-modal .cs-search-bar {
    margin-bottom: 16px !important;
  }

  .cs-form-textarea {
    margin-bottom: 12px !important;
  }

  .cs-form-group .react-calendar {
    width: 100%;
  }

  .cs-form-group .form-control,
  .cs-form-group .cs-select-group,
  .dropdown .dropdown-toggle {
    height: 36px;
  }

  .radio-button {
    width: 16px;
    height: 16px;
  }

  .cs-breadcrumb span svg {
    width: 12px;
    min-width: 12px;
    max-width: 12px;
    height: 12px;
    min-height: 12px;
    max-height: 12px;
  }

  .cs-avatar-row .profile span.cs-primary {
    height: 14px;
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
    width: 14px;
  }
  span svg {
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
  }

  .cs-btn-primary .cs-common-spinner svg {
    width: 12px;
    min-width: 12px;
    max-width: 12px;
    height: 12px;
    min-height: 12px;
    max-height: 12px;
  }

  .modal-content .cs-text-center span.icon.cs-icon svg,
  .cs-storageplan svg {
    width: 80px;
    height: 80px;
    max-height: 80px;
    max-width: 80px;
    min-height: 80px;
    min-width: 80px;
  }

  .cs-avatar-xs {
    width: 28px;
    height: 28px;
  }

  .cs-export-row {
    margin-top: 16px;
  }

  .cs-custom-pagination {
    width: 100%;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .cs-custom-pagination .cs-common-add-dropdown span.cs-light-body-text-m.cs-neutral-80,
  .cs-custom-pagination div.cs-light-body-text-m.cs-neutral-80 {
    display: none;
  }

  .cs-pagination-arrow {
    margin-top: -32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: center;
    width: 100%;
  }

  .cs-modal-btn button:last-child {
    margin-left: 8px;
  }

  .cs-radio-group .cs-radio-btn {
    margin-right: 16px;
  }

  .cs-form-dropdown .dropdown-item {
    padding: 8px 16px;
  }

  .cs-create-company .cs-form-group {
    padding-inline: 8px;
  }

  .cs-upload-container,
  .cs-off-canvas .top-information-container {
    padding: 16px;
  }

  .cs-user-td .row {
    padding-block: 8px;
  }

  /* ========== Toast CSS start ========== */
  .cs-toast .Toastify__toast-container {
    width: 280px;
    left: auto;
    margin-right: 0;
    margin-top: 54px;
  }

  .cs-toast .Toastify__toast-body {
    margin-right: 10px;
    font-size: 10px;
    line-height: 14px;
  }

  .cs-toast .Toastify__close-button>svg {
    margin-top: 0;
  }

  .cs-toast .Toastify__toast-icon {
    width: 14px;
  }

  .cs-toast .Toastify__toast {
    padding-block: 10px;
  }

  /* ========== Toast CSS end ========== */
  .modal-dialog {
    padding: 14px;
  }

  .cs-off-canvas {
    padding: 24px 16px;
  }

  .offcanvas.offcanvas-end {
    width: calc(100% - 40px);
    height: calc(100% - 20px);
  }

  .cs-modal-padding-null .modal-body {
    padding-bottom: 0;
  }

  .cs-table-icons span {
    width: 20px;
    height: 20px;
    padding: 4px;
  }

  .cs-table-icons span svg {
    width: 12px;
    min-width: 12px;
    max-width: 12px;
    height: 12px;
    min-height: 12px;
    max-height: 12px;
  }

  .cs-thead th,
  .cs-tbody td {
    font-size: 12px;
    line-height: 16px;
  }

  .cs-search-result {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }

  .cs-dashboard-cards .card .card-icon .cs-icon svg {
    width: 30px;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
  }

  .settings-main-section .color-row .cs-btn-icon-tertiary {
    margin: 0;
    top: 174px !important;
    padding: 0 !important;
  }

  .settings-main-section .choose-image-slider .image-box {
    height: max(100px, 80px);
  }

  .settings-main-section .template1-section .align-items-center.row .template-right-section {
    padding-inline: 24px;
  }

  .settings-main-section .template4-section .row:first-child .col:not(.col:first-child) {
    padding-inline: unset;
  }

  .settings-main-section .template4-section .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .settings-main-section .template4-section .template-right-section {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }

  .settings-main-section .template4-section {
    padding-inline: 20px;
  }

  .settings-main-section .fullscreen-preview .template3-section {
    padding: 32px;
  }

  .settings-main-section .branding-section .branding-container .fullscreen-preview.dashboard-full-preview {
    padding-inline: 16px !important;
  }

  .settings-main-section .template2-section .template-right-section {
    max-width: 50vw;
    padding-inline: 16px;
  }

  .settings-main-section .template2-section .align-items-center.row>.col {
    padding-block: 20px;
  }

  .settings-main-section .fullscreen-preview .template2-section .align-items-center.row>.col {
    padding-block: 32px;
  }

  .settings-main-section .fullscreen-preview .template2-section .template-right-section {
    min-width: 80vw;
  }

  .cs-nav-dropdown .cs-dropdown-menu::before {
    height: calc(100% - 21px);
  }

  .favicon-small .advanced-cropper-boundary.advanced-cropper__boundary,
  .favicon-Avatar .advanced-cropper-boundary.advanced-cropper__boundary {
    height: 89vw;
  }

  .cs-dropdown.select-dropdown .cs-regular-body-text-m {
    font-size: 12px;
  }

  .cs-dropdown.select-dropdown .cs-regular-body-text-m .css-olqui2-singleValue {
    color: var(--neutral-60) !important;
    margin-left: 8px;
  }

  .mobile-add-icon {
    bottom: 30px;
  }

  .payment-cards .credit-payment-card .credit-box {
    padding: 12px 16px;
  }

  .payment-cards .credit-box svg {
    width: 20px;
    height: 20px;
  }

  .payment-cards .credit-box span.delete-action {
    padding: 6px;
  }

  .payment-cards .credit-payment-card .credit-box .credit-text p.default-regular-body-text-xl {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .payment-cards {
    margin-top: 24px;
  }

  .bank-details,
  .credit-change-method {
    padding: 12px 14px;
    width: 100%;
  }

  .cs-card-logo {
    margin-right: 8px;
  }

  .change-card-delete {
    padding: 6px;
  }

  .cs-subcription-table table .cs-tfoot tr td {
    font-size: 12px;
  }

  .cs-subcription-table table .cs-tbody tr td,
  .cs-subcription-table .cs-table th {
    padding-inline: 4px !important;
  }

  .cs-subcription-table .cs-table th:last-child,
  .cs-subcription-table .cs-table tr td:last-child {
    padding-inline: 4px 0 !important;
  }

  .cs-subcription-table .cs-table th:first-child,
  .cs-subcription-table .cs-table tr td:first-child {
    padding-inline: 0 4px !important;
  }

  .cs-subcription-table table .cs-tfoot tr td {
    padding-block: 14px 0 !important;
  }

  .cs-subcription-table table .cs-tbody tr:last-child td {
    padding-bottom: 14px !important;
  }

  .cs-header-right .cs-header-dropdown .dropdown-menu {
    left: -150px;
    top: 32px;
    width: 280px;
  }
}

@media (max-width: 425px) {}

@media (max-width: 390px) {

  .cs-md-modal .modal-footer,
  .modal-footer,
  .cs-lg-modal .modal-footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .cs-md-modal .modal-footer button,
  .modal-footer button,
  .cs-lg-modal .modal-footer button {
    max-width: 100%;
    margin: 0;
  }

  .cs-md-modal .modal-footer button:last-child,
  .modal-footer button:last-child,
  .cs-lg-modal .modal-footer button:last-child {
    margin-top: 16px;
  }

  .modal-footer button:last-child {
    margin-left: 0;
  }

  .company-details-modal .cs-md-modal .modal-footer button:last-child,
  .company-details-modal .cs-lg-modal .modal-footer button:last-child {
    margin-top: 0;
    margin-left: 16px;
  }

  .cs-profile-update .cs-modal-btn button:last-child,
  .cs-modal-multi-button .modal-footer button+button {
    margin-left: 0;
  }

  .cs-assign-modal .modal-footer button:last-child {
    margin: 0 !important;
  }
}

@media (max-width: 360px) {

  .cs-lg-modal,
  .cs-md-modal,
  .cs-s-modal,
  .cs-xl-modal,
  .modal-dialog.cs-s-modal,
  .modal-dialog {
    padding: 24px 12px;
    margin: 0;
  }
}

@media (max-width: 320px) {
  .billing-box {
    padding: 16px;
  }

  .billing-box .cs-flex .cs-semi-bold-h1 {
    font-size: 16px;
    line-height: 26px;
  }

  .xl-btn {
    max-width: calc(100% - 20vw);
  }
}

/* ========== END Mobile - Viewport ========== */